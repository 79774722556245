// extracted by mini-css-extract-plugin
export var contactBox = "thankYou-module--contact-box--74920";
export var contactSection = "thankYou-module--contact-section--639e3";
export var contactText = "thankYou-module--contact-text--0918f";
export var container = "thankYou-module--container--6915e";
export var icon = "thankYou-module--icon--08ab5";
export var image = "thankYou-module--image--46cf5";
export var innerContainer = "thankYou-module--inner-container--b9d32";
export var thankYouSubText = "thankYou-module--thank-you-sub-text--385e6";
export var thankYouText = "thankYou-module--thank-you-text--caad3";
export var wrapperBottom = "thankYou-module--wrapper-bottom--c3f2c";